.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 30px;
  margin-top: 50px;
}

.modalContent {
  background-color: #fff;
  padding-left: 35px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 30px;
  border-radius: 10px;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 200px;
  max-width: 600px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  transform: translateY(+50px);
  position: relative;
  margin-bottom: 100px;
}

.modal.show {
  background-color: rgba(0, 0, 0, 0.9);
}

.modalContent.show {
  opacity: 1;
  transform: translateY(0px);
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 1.7rem;
  cursor: pointer;
}

.title_modal {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 800;
  white-space: normal;
  word-wrap: break-word;
}

.content_modal {
  white-space: normal;
  word-wrap: break-word;
}

.close:hover,
.close:focus {
  color: rgb(113, 113, 113);
  text-decoration: none;
  cursor: pointer;
}