.quiz-container {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;
    padding: 30px;
    border: 1px solid rgb(198, 198, 198);
    border-radius: 10px;
}

.question-count {
    margin-bottom: 10px;
    color: grey;
    font-size: 1.0rem;
}

.question-text {
    margin-bottom: 10px;
    font-size: 1.5em;
    font-weight: bold;
    border-radius: 10px;
}

.answer-section button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 1.1rem;
    color: white;
    background-color: #222;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.answer-section button:hover {
    background-color: #333;
}

.score-section {
    font-size: 1.2em;
    padding: 15px;
    text-align: left;
    border: 1px solid rgb(198, 198, 198);
    border-left: 5px solid rgb(80, 80, 80);
    border-radius: 10px;
}