.blogPage {
    padding: 20px;
    max-width: 600px;
    margin: auto;
  }
  
  .title {
    font-size: 2rem;
    color: #333;
    text-align: center;
  }
  
  .post {
    background-color: #ffffff;
    border-left: 7px solid #0066d4;
    border-top: 1px solid rgb(216, 216, 216);
    border-right: 1px solid rgb(216, 216, 216);
    border-bottom: 1px solid rgb(216, 216, 216);
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .post:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
    cursor: pointer;
  }
  
  .postDate {
    color: #666;
  }
  
  .postSummary {
    font-size: 1rem;
    color: #333;
  }