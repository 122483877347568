.designContent {
    padding: 20px;
    max-width: 500px;
    margin: auto;
}

.lineAndItem {
    display: flex;
    align-items: center;
}

.line {
    flex: 0 0 25px;
    height: 3px;
}

.iconY{
    color: rgb(187, 26, 26);
}

.iconB{
    color: rgb(53, 26, 187);
}

.iconD{
    color: rgb(209, 7, 125);
}

.iconI{
    color: rgb(209, 7, 101);
}

.iconP{
    color: rgb(30, 30, 30);
}

.listItem {
    display: flex;
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 25px;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
}

.link {
    background-color: rgb(255, 255, 255);
    align-self: stretch;
    margin-bottom: 24px;
    border-radius: 6px;
    margin-left: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    border: 1px solid rgb(255, 26, 26);
}


.link:hover {
    box-shadow: 0px 7px 10px rgba(146, 146, 146, 0.181);
    transform: scale(1.05);

    transition: box-shadow 0.2s ease, transform 0.2s ease;
    cursor: pointer;
}

.heading {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
}

.section {
    margin-bottom: 50px;
}

.list {
    list-style-type: none;
    border-left: 3px solid #ccc;
}

.video {
    border-radius: 5px;
    border: none;
    margin-bottom: 10px;
}